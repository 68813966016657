@import "~@/assets/scss/global.scss";































































































































































































































































































































































































::v-deep {
    .el-dialog__body {
        padding: 10px;
    }
}

.el-select-dropdown {
    ::v-deep {
        .el-select-dropdown__empty {
            color: red !important;
        }
    }
}

.error {
    color: red;
}

.branch {
    li {
        height: auto !important;
        line-height: 30px;

        .title {
            display: flex;
            height: 32px;
            font-weight: bold;
        }

        .quantity {
            text-align: right;
        }

        .address {
            color: #909399;
            font-size: 12px;
        }
    }

    li+li {
        border-top: 0.5px solid #d8dadf;
    }
}

@import "~@/assets/scss/global.scss";
























































































































































































































































































































































































































::v-deep {
    .el-header {
        .receiver {
            .el-input__inner {
                width: 190px !important;
            }
        }

        .timeType {
            margin-right: 2px;
            height: 35px;
            vertical-align: middle;

            .el-input__inner {
                width: 100px !important;
            }
        }

        .el-input {
            .el-input__inner {
                width: 150px;
            }
        }

        .operate {
            margin-bottom: 10px;

            &>div {
                display: inline-block;
            }

            .el-button,
            .el-checkbox {
                margin-right: 10px;
                margin-left: 0px;
            }
        }

    }

    .el-main {

        .el-button {
            margin-right: 5px;
            display: inline-block;
            padding: 9px;
        }

        .expansion {
            white-space: pre-line;
            width: 10.3rem;
            overflow: hidden;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }
}
